import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import BetSlipContext from "../../context/BetSlipContext";
import Keyboard from "./Keyboard";

const Toss = ({
  data,
  details,
  betType,
  back_odds,
  tossObj,
  setSlipObj,
  slipObj,
  profileData,
  handelBookmakerPositions,
  resetSelection,
  user,
  messaageBox,
  bottomSpaceClass,
}) => {
  let {
    handelPlaceBetfair,
    setBetFairObj,
    betSelectionObj,
    setBetSelectionObj,
  } = useContext(BetSlipContext);
  const [popupMessage, setPopupMessage] = useState("");
  const [selectedBet, setSelectedBet] = useState({
    selectionId: "",
    teamName: "",
    check: "",
  });
  const [key, setKey] = useState("");
  const [amountRange, setAmountRange] = useState({
    min: 1,
    max: 100,
    oddsLimit: 1000,
  });

  return (
    <div id="bookMakerWrap" className={bottomSpaceClass}>
      <div
        className={`bets-wrap bets-bookmaker ${
          details.bookMaker == "off"
            ? "disabled"
            : amountRange.oddsLimit == 0
            ? "disabled"
            : ""
        }`}
        style={{ display: "block" }}
      >
        <h4 className="d-flex justify-content-between w-75">
          <a id="multiMarketPin" className="pin-off" href="#">
            Pin to Multi Markets
          </a>
          <span style={{ width: "70%" }}>Who will win the toss</span>
          <span style={{ width: "20%" }}>Back</span>
        </h4>
        {data.map((res, i) => {
          if (i <= 1) {
            const the_odds_back = back_odds;
            let filterOddsBack = the_odds_back
              ?.filter((todd) => todd.ri == res.SelectionId)
              ?.sort((a, b) => {
                return a - b;
              });
            return (
              <>
                <dl className="bets-selections" style={{ display: "flex" }}>
                  <dt style={{ background: "white" }}>
                    <h4 id="runnerName">{res.RunnerName}</h4>
                    <ul id="winLoss">
                      {Math.abs(res.position) != 0 &&
                        !isNaN(Math.abs(res.position)) && (
                          <li
                            id="withBet"
                            class={res.position <= 0 ? "lose" : "win"}
                            style={{ display: "list-item" }}
                          >
                            {parseFloat(Math.abs(res.position)).toFixed(2)}
                          </li>
                        )}

                      {Math.abs(res.newPosition) != 0 &&
                        !isNaN(Math.abs(res.newPosition)) && (
                          <li
                            id="withBet"
                            class={res.newPosition <= 0 ? "to-lose" : "to-win"}
                            style={{ display: "list-item" }}
                          >
                            ( {parseFloat(Math.abs(res.newPosition)).toFixed(2)}
                            )
                          </li>
                        )}
                    </ul>
                  </dt>

                  <dd
                    id="back_1"
                    style={{ width: "38%" }}
                    onClick={() => {
                      setKey(res?.SelectionId);
                      setBetSelectionObj({
                        ...betSelectionObj,
                        betType: betType,
                        oddsSelect:
                          filterOddsBack?.length > 0
                            ? filterOddsBack[0]?.rt
                            : "",
                        layorback: "back",
                        inputType: "price",
                        priceToogle: true,
                        priceToogleType: "toss",
                        gameType: details?.gameType,
                      });
                      handelBookmakerPositions({
                        ...res,
                        teamName: res?.RunnerName,
                        ...details,
                      });
                      setSlipObj({
                        ...res,
                        teamName: res?.RunnerName,
                        ...details,
                      });
                      setSelectedBet({
                        selectionId: res?.SelectionId,
                        teamName: res?.RunnerName,
                        tossMarketId:tossObj?.tossMarketId,
                        check: "back",
                      });
                    }}
                  >
                    <a style={{ border: "none" }}>
                      {filterOddsBack?.length > 0 ? filterOddsBack[0]?.rt : ""}
                      <span>
                        {filterOddsBack?.length > 0
                          ? filterOddsBack[0]?.bv
                          : ""}
                      </span>
                    </a>
                  </dd>
                </dl>
                {betSelectionObj?.priceToogle &&
                betSelectionObj?.priceToogleType == "toss" &&
                key == res.SelectionId &&
                betSelectionObj?.betType == "toss" ? (
                  <Keyboard
                    details={details}
                    betType={betType}
                    selectedBet={selectedBet}
                    setSlipObj={setSlipObj}
                    resetSelection={resetSelection}
                    handelPlaceBetfair={handelPlaceBetfair}
                    amountRange={amountRange}
                  />
                ) : (
                  ""
                )}
              </>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Toss;

import React from "react";

const Aboutus = () => {
  return (
    <>
      <div className="side-head">
        <h3>About Us</h3>
      </div>
      <div className="announce-wrap">
        <p>
          Cric Infotech Group is one of the upcoming providers for online gaming
          entertainment across Sports Betting, Online and Live Casino operating
          in the emerging and the regulated markets.
        </p>
        <p>
          We aim to utilize the latest technologies to provide innovative and
          interactive gaming experiences in a secure environment. We have
          dedicated ourselves to offering our customers a seamless and thrilling
          gaming experience while you are on the go. We aim to provide an
          exceptional and fully customizable online betting experience.
        </p>
        <p>
          We are innovative, ambitious and passionate about what we do. We do it
          in a credible and responsible way, always aiming for the top. We only
          operate in regulated markets where we hold the appropriate licenses.
          We take our responsibilities to customers and our other stakeholders
          seriously and place great emphasis on working to a ‘compliance first’
          model across the business.
        </p>
        <p>
          Dedicated Customer Service Team: We are here for you every step of the
          way with dedicated customer service managers standing by to provide
          you with a 24/7 top notch customer care service, handling any issues
          quickly and efficiently. When customers bet on our site they can rest
          assured that they are getting a wide variety of betting options, up to
          date information and the best odds available. Our customers also have
          peace of mind, knowing that when it’s time to collect, they are
          betting with a well-known reputable company. We have integrated best
          and secured payment methods on our site and a transaction process that
          is quick, easy enabling our players to cash out their winnings quickly
          and securely.
        </p>
        <strong className="mb-2">BUSINESS ADDRESS</strong>
        <ul className="about-ul">
          <li>Name- Cric Infotech LTD</li>
          <li>Address- Abraham de Veerstraat 9 , Curacao P.O Box 3421</li>
          <li>Name- Cric Technology Limited</li>
          <li>Address- 71-75 Shelton Street.</li>
        </ul>
      </div>
    </>
  );
};

export default Aboutus;

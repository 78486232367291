import { isEmpty, isNumber } from "lodash";
import React, {
  startTransition,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthProvider from "../../context/AuthContext";
import BetSlipContext from "../../context/BetSlipContext";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import Bookmaker from "./Bookmaker";
import MatchDetails from "./MatchDetails";
import Fancy from "./Fancy";
import ReactPlayer from "react-player";
import { categoryArray, premiumCategoryArray } from "../../Utils/constants";
import helpers from "../../Utils/helpers";
import PremiumFancyNew from "./PremiumFancyNew";
import axios from "axios";
import moment from "moment";
import obj from "../../Utils/constants";
import { io } from "socket.io-client";
import LoadingImage from "../../assets/images/cover-live.png";
import secureLocalStorage from "react-secure-storage";
import Toss from "./Toss";
import { useScrollPosition } from "react-use-scroll-position";
function BidDetail() {
  const parmas = useParams();
  const navigate = useNavigate();
  let {
    user,
    setLoader,
    setIsTv,
    isTv,
    profileData,
    setFancyRules,
    setPremiumFancyRules,
    setMarketDepth,
    setMarketDepthData,
    loader,
    parly,
    setSocketId,
  } = useContext(AuthProvider);
  let {
    handelAddMultiMarket,
    multimarketLoader,
    betLoader,
    setBetLoader,
    betSelectionObj,
    isBetPlaced,
    betPlacedCheck,
    setBetPlacedCheck,
    messaageBox,
    updatePosition,
    setBetSelectionObj,
  } = useContext(BetSlipContext);
  const { y } = useScrollPosition();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [details, setDetails] = useState({});
  const [totalMatched, setTotalMatched] = useState({});
  const [selections, setSelections] = useState([]);
  const [jsonTieData, setJsonTieData] = useState([]);
  const [position, setPosition] = useState({});
  const [bookmakerData, setBookmerData] = useState({});
  const [bookmakerSelections, setBookmakerSelections] = useState([]);
  const [back_odds, setBackOdds] = useState([]);
  const [lay_odds, setLayOdds] = useState([]);
  const [back_oddsTie, setBackOddsTie] = useState([]);
  const [lay_oddsTie, setLayOddsTie] = useState([]);
  const [back_oddsToss, setBackOddsToss] = useState([]);
  const [tossObj, setTossObj] = useState([]);
  const [tempSelection, setTempSelection] = useState([]);
  const [selectionDemo, setSelectionDemo] = useState([]);
  const [fancyList, setFancyList] = useState([]);
  const [betFairMs, setBetFairMs] = useState(1);
  const [fancyPosition, setFancyPosition] = useState([]);
  const [tossList, setTossList] = useState([]);
  const [premiumFancyPosition, setPremiumFancyPosition] = useState([]);
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [fancyCategory, setFancyCategory] = useState([]);
  const [fancyCategoryTabArray, setFancyCategoryTabArray] = useState([]);
  const [preminumFancy, setPremiumFancy] = useState([]);
  const [preminumFancyTab, setPremiumFancyTab] = useState([]);
  const [mute, setMute] = useState(true);
  const [condi, setCondi] = useState(false);
  const [key, setKey] = useState("Fancy Bet");
  const [popupMessage, setPopupMessage] = useState(false);
  const [slipObj, setSlipObj] = useState({});
  const [marketName,setMarketName] = useState('Match Odds')
  const [selectedTab, setSelectedTab] = useState("All");
  const [scoreId, setScoreId] = useState("0");
  const [minSportBook, setMinSportBook] = useState(1);
  const [seek, setSeek] = useState(true);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [checkTvRefresh, setTvCheckRefresh] = useState(true);
  let [pop, setPop] = useState("");
  const [controls, setControls] = useState(true);
  const [showInplaySlip, setShowInplaySlip] = useState(false);
  const ref = useRef(null);
  const currentOdds = useRef(betSelectionObj?.betType); // same value
  const [tScore, setTScore] = useState();
  const [tab, setTab] = useState("match");
  const [activeSportBook, setActiveSportBook] = useState([]);
  const setSelectedTabFunc = (id) => {
    if (id == "All") {
      setFancyCategoryTabArray(fancyList);
    } else if (id == "More") {
      let arr = fancyList?.filter(
        (res) =>
          res?.categoryType !== 1 &&
          res?.categoryType !== 2 &&
          res?.categoryType !== 3 &&
          res?.categoryType !== 4 &&
          res?.categoryType !== 5 &&
          res?.categoryType !== 6 &&
          res?.categoryType !== 7 &&
          res?.categoryType !== 8 &&
          res?.categoryType !== 9 &&
          res?.categoryType !== 10 &&
          res?.categoryType !== 11 &&
          res?.categoryType !== 14
      );
      setFancyCategoryTabArray(arr);
    } else {
      let temp = fancyList?.filter((res) => {
        return res?.categoryType == id;
      });
      setFancyCategoryTabArray(temp);
    }
    setSelectedTab(id);
  };
  const [marketNew, setMarketNew] = useState([]);
  const getMatchDetails = async (typeCheck = "") => {
    if (typeCheck !== "interval") {
      setLoader(true);
    }
    let temp = user
      ? `?eventId=${parmas?.eventId}&userId=${user ? user?.user?._id : ""}`
      : `?eventId=${parmas?.eventId}`;
    const { status, data: response_users } = await apiGet(
      apiPath.matchDetail + temp
    );
    if (status === 200) {
      if (response_users?.success) {
        if (typeCheck == "") {
          if (response_users?.results?.marketList?.length > 1) {
            let latest = response_users?.results?.marketList?.filter(
              (res) => {
                return res?.jsonData?.length > 0;
              }
            );
            latest?.forEach(function(item,i){
              if(item.market === "Match Odds"){
                latest.splice(i, 1);
                latest.unshift(item);
              }
            });
            let temp = latest?.sort((a, b) => {
              return a.market > b.market;
            });
            setMarketNew(temp || []);
            let filteredArray =  latest?.filter((res) => {
              return res?.marketId == parmas?.marketId;
            })
            setSelections(
              filteredArray[0]?.jsonData || []
            );
            setTempSelection(
              filteredArray[0]?.jsonData || []
            );
            setMarketName(filteredArray[0]?.market)
          } else {
            setSelections(response_users?.results?.jsonData || []);
            setTempSelection(response_users?.results?.jsonData || []);
          }
          const result = {
            centralizedId: response_users?.results?.centralizedId || "",
            bookmakerCentralizedId:
              response_users.results?.bookmakerCentralizedId || "",
          };
          response_users?.results?.jsonData?.map((it) => {
            it.position = 0;
          });
          const fancyCentralizedIdArr = response_users?.results?.fancyList?.map(
            (rt) => rt.centralizedId
          );
          let temp = categoryArray?.filter((f) =>
            response_users?.results?.fancyList?.some(
              (item) => item?.categoryType === f.type
            )
          );
          // setFancyCategoryTabArray(response_users?.results?.fancyList || []);
          // setFancyCategory(
          //   temp?.length > 0
          //     ? [
          //         { type: "All", name: "All" },
          //         ...temp,
          //         { type: "More", name: "More" },
          //       ]
          //     : [{ type: "All", name: "All" }]
          // );
          setScoreId(
            response_users?.results.scoreId == undefined
              ? "0"
              : response_users?.results.scoreId
          );
          setIsTv({
            id:
              response_users?.results?.channel == false ||
              response_users?.results?.channel == ""
                ? false
                : response_users?.results?.channel,
            status: response_users?.results?.channel ? true : false,
          });
          setJsonTieData(response_users?.results?.jsonTieData || []);
          setMarketDepthData({
            array: response_users?.results?.jsonData || [],
            matchName: response_users?.results?.eventName,
          });
          setDetails(response_users?.results);
          setTotalMatched(response_users?.results?.totalMatched);
          setLoader(false);
          setSelectionDemo(response_users?.results?.jsonData || []);

          setBookmakerSelections(response_users?.results?.jsonData || []);
          setTossList(response_users?.results?.jsonData || []);
          if (
            response_users?.results?.hasFancy ||
            response_users?.results?.provider == "diamond"
          ) {
            setKey("Fancy Bet");
          } else {
            if (
              response_users?.results?.gameType == "cricket" &&
              !response_users?.results?.eventName.includes(" SRL ")
            ) {
              if (
                response_users?.results?.fancyList?.length == 0 &&
                !isEmpty(user)
              ) {
                if (response_users?.results.fancy == "off") {
                  setKey("Preminum Fancy");
                } else if (response_users?.results.premiumFancy == "off") {
                  setKey("Fancy Bet");
                } else {
                  !response_users?.results?.eventName.includes(" T10 ")
                    ? setKey("Preminum Fancy")
                    : setKey("Fancy Bet");
                }
              } else {
                if (response_users?.results.fancy == "off") {
                  setKey("Preminum Fancy");
                } else if (response_users?.results.premiumFancy == "off") {
                  setKey("Fancy Bet");
                } else {
                  setKey("Fancy Bet");
                }
              }
            } else {
              setKey("Preminum Fancy");
            }
          }
        } else {
          setDetails(response_users?.results);
          setTotalMatched(response_users?.results?.totalMatched);
        }
        setCheckRefresh(true);
      }
      setLoader(false);
    }
  };

  const getPremiumFancyList = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        let temp =
          response_users?.results?.data?.length > 0
            ? response_users?.results?.data?.filter((res) => {
                return res?.sportsBookSelection?.length > 0;
              })
            : [];
        setPremiumFancy(
          temp?.length > 0
            ? temp?.map((res, index) => {
                return { ...res, check: index < 5 ? true : false };
              })
            : []
        );
        // let temp1 = premiumCategoryArray?.filter((f) =>
        //   response_users?.results?.data?.some(
        //     (item) => item.categoryType === f.type
        //   )
        // );
        // setPremiumFancyTabArr(response_users?.results.data || []);
        setPremiumFancyTab([
          { type: "All", name: "All" },
          ...premiumCategoryArray,
          { type: "More", name: "More" },
        ]);
      }
    }
  };

  const getMatchData = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${!isEmpty(id) ? id : parmas.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          parmas?.eventId == socket_data?.eventId && socket_data?.mi == (!isEmpty(id) ? id : parmas.marketId)
        ) {
          if (socket_data.ms) {
            setBetFairMs(socket_data.ms);
          }
          if (socket_data.rt?.length) {
            const back_odds = socket_data?.rt?.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data?.rt?.filter((rt) => !rt.ib) || [];
            setBackOdds(back_odds);
            setLayOdds(lay_odds);
            setTotalMatched(socket_data?.totalMatched);
          }
          if (!isEmpty(id)) {
            setCheckRefresh(true);
          }
        }
      }
    }
  };
  //new 14 9 2023
  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          let temp = categoryArray?.filter((f) =>
            message?.results?.some((item) => item?.categoryType === f.type)
          );

          setFancyCategory(
            temp.length > 0
              ? [
                  { type: "All", name: "All" },
                  ...temp,
                  { type: "More", name: "More" },
                ]
              : [{ type: "All", name: "All" }]
          );
          setPremiumFancyTab([
            { type: "All", name: "All" },
            ...premiumCategoryArray,
            { type: "More", name: "More" },
          ]);
          setFancyPosition((prev) => {
            setFancyList(
              message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) =>
                    item.selectionId == res.selectionId &&
                    item.fancyName == res.name
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                };
              })
            );

            setFancyCategoryTabArray(
              message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) =>
                    item.selectionId == res.selectionId &&
                    item.fancyName == res.name
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                };
              })
            );
            return prev;
          });
        }
      }
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      getMatchDetails("interval");
      getPremiumFancyList();
      if (!parly) {
        getFancyData();
        getMatchData();
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [parly,parmas]);

  useEffect(() => {
    getMatchDetails();
    getPremiumFancyList();
    if (!parly) {
      getFancyData();
      getMatchData();
    }
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      getMatchDetails("interval");
      getPremiumFancyList();
      if (!parly) {
        getFancyData();
        getMatchData();
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    currentOdds.current = betSelectionObj?.betType;
  }, [betSelectionObj?.betType]);

  useEffect(() => {
    if (multimarketLoader) {
      setTimeout(() => {
        getMatchDetails("multi");
      }, 1000);
    }
  }, [multimarketLoader]);

  const listenSportBook = (message) => {
    const socket_data = message;
    if (socket_data.results && socket_data.eventId == parmas.eventId) {
      if (!isEmpty(user)) {
        setPremiumFancyPosition((prev) => {
          setPremiumFancy((current) => {
            let updatedArray = socket_data?.results;
            return updatedArray?.map((res) => {
              let obj = prev?.filter((item) => {
                return item?.fancyName == res?.marketName;
              });
              if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
                return {
                  ...res,
                  sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                    let temp = obj?.find(
                      (item) => elem?.id == item?.fancySelectionId
                    );
                    return {
                      ...elem,
                      position: temp?.position,
                    };
                  }),
                };
              } else {
                return res;
              }
            });
          });
          return prev;
        });
      }
    }
  };


  const listenBetfairOdds = (event) => {
    if (event?.results[0]?.mi == parmas?.marketId) {
      const socket_data =
        (event &&
          event?.results?.find((item) => item.eventId == parmas?.eventId)) ||
        [];
      if (
        socket_data &&
        socket_data?.eventId &&
        parmas?.eventId == socket_data?.eventId
      ) {
        if (socket_data.ms) {
          setBetFairMs(socket_data.ms);
        }
        if (socket_data.rt?.length) {
          const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
          const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
          setBackOdds(back_odds);
          setLayOdds(lay_odds);
          setTotalMatched(socket_data?.totalMatched);
        }
      }
    }
  };

  const listenDiamondFancy = (message) => {
    if (message?.eventId === parmas?.eventId) {
      if (message?.results?.length > 0) {
        let temp = categoryArray?.filter((f) =>
          message?.results?.some((item) => item?.categoryType === f.type)
        );
        setFancyCategory(
          temp.length > 0
            ? [
                { type: "All", name: "All" },
                ...temp,
                { type: "More", name: "More" },
              ]
            : [{ type: "All", name: "All" }]
        );
        setPremiumFancyTab([
          { type: "All", name: "All" },
          ...premiumCategoryArray,
          { type: "More", name: "More" },
        ]);
        if (!isEmpty(user)) {
          setFancyPosition((prev) => {
            setFancyList(
              message.results.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );
            setFancyCategoryTabArray(
              message.results.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );
            return prev;
          });
        } else {
          setFancyList((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
          setFancyCategoryTabArray((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
        }
      }
    }
  };

  const listenBookmakerData = (message) => {
    if (message.eventId === parmas?.eventId) {
      setBookmerData(message?.results);
      setBookmakerSelections((prev) => {
        return prev?.map((res) => {
          let temp = message?.results?.rt.find((item) => {
            return item?.name === res?.RunnerName;
          });
          if (temp?.name === res?.RunnerName) {
            return {
              ...res,
              ...temp,
            };
          } else {
            return { ...res, ...temp };
          }
        });
      });
      const back_odds = message?.results?.rt.filter((rt) => rt.ib) || [];
      const lay_odds = message?.results?.rt.filter((rt) => !rt.ib) || [];
      setBookmakerLayOdds(lay_odds);
      setBookmakerBackOdds(back_odds);

      // if (currentOdds.current == "bookmaker") {
      //   setMatchLastOdds({
      //     matchOdds: { lay: [...lay_odds], back: [...back_odds] },
      //   });
      // }
      // console.log(bookmakerSelections, message?.results?.rt)
      // setBookmakerSelections(message.results[0]['bm1'])
    }
  };

  const listenCcoreT10 = (message) => {
    if (message?.results?.eventId === parmas?.eventId) {
      // console.log("message?.results", message?.results?.score);
      setTScore(message?.results?.score || {});
    }
  };

  const listenTie = (message) => {
    const socket_data =
      (message &&
        message?.results?.find((item) => item.eventId == parmas?.eventId)) ||
      [];
    if (
      socket_data &&
      socket_data?.eventId &&
      parmas?.eventId == socket_data?.eventId
    ) {
      if (socket_data.rt?.length) {
        const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
        const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
        setBackOddsTie(back_odds);
        setLayOddsTie(lay_odds);
      }
    }
  };

  const listenToss = (message) => {
    const socket_data =
      (message &&
        message?.results?.find((item) => item.eventId == parmas?.eventId)) ||
      [];
    if (
      socket_data &&
      socket_data?.eventId &&
      parmas?.eventId == socket_data?.eventId
    ) {
      if (socket_data.rt?.length) {
        const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
        setBackOddsToss(back_odds);
        setTossObj(socket_data);
      }
    }
  };

  const [socketObj, setSocketObj] = useState(null);
  const getSocket = () => {
    if (details?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${
            details?.socketUrl
              ? details?.socketUrl
              : process.env.REACT_APP_API_BASE_URL1
          }?token=${randomId}&userType=front&eventId=${details?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${
            details?.socketUrl
              ? details?.socketUrl
              : process.env.REACT_APP_API_BASE_URL1
          }?user_id=${randomId}&eventId=${details?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setSocketId(newSocket);
      if (!isEmpty(user)) {
        if (parly) {
          newSocket.on("listenPremiumFancy", listenSportBook);
          newSocket.emit("setPremiumFancy", {
            user_id: user?.user?._id ? user?.user?._id : randomId,
            eventId: parmas.eventId,
          });
        } else {
          newSocket.on("listenDiamondFancy", listenDiamondFancy);
          newSocket.on("listenBetFairOdds", listenBetfairOdds);
          newSocket.on("listenBookmakerOdds", listenBookmakerData);
          newSocket.on("listenPremiumFancy", listenSportBook);
          newSocket.on("listenBetFairTieOdds", listenTie);
          newSocket.on("listenBetFairTossOdds", listenToss);
          newSocket.on("scoreT10", listenCcoreT10);
          // newSocket.on("refreshPage", refreshPage);
          newSocket.emit("setPremiumFancy", {
            user_id: user?.user?._id ? user?.user?._id : randomId,
            eventId: parmas.eventId,
          });
        }
      } else {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
        // newSocket.on("refreshPage", refreshPage);
      }
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.disconnect();
    }
  };

  useEffect(() => {
    if (checkRefresh && details?.eventId) {
      getSocket();
    }
  }, [checkRefresh, details]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !isEmpty(user) && !checkRefresh) {
      setCheckRefresh(true);
      setTvCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
      setTvCheckRefresh(false);
    }
  });

  useEffect(() => {
    let interval = setInterval(() => {
     getSocket()
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getBetPosition = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${parmas.eventId}&type=${details.gameType}&marketId=${
          !isEmpty(id) ? id : parmas?.marketId
        }`
    );
    if (status === 200) {
      //BET FAIR
      if (response_users.success) {
        // if (response_users?.results?.betFair?.length > 0) {
        //   secureLocalStorage.setItem(
        //     "betFair",
        //     response_users?.results?.betFair
        //   );
        // }
        // if (response_users?.results?.bookmaker?.length > 0) {
        //   secureLocalStorage.setItem(
        //     "bookmaker",
        //     response_users?.results?.bookmaker
        //   );
        // }
        // if (response_users?.results?.tie?.length > 0) {
        //   secureLocalStorage.setItem("tie", response_users?.results?.tie);
        // }
        // if (response_users?.results?.toss?.length > 0) {
        //   secureLocalStorage.setItem("toss", response_users?.results?.toss);
        // }
        updatePositionNew(response_users?.results);
        setPosition(response_users?.results);
        setBetLoader(false);
      }
    }
  };

  const getFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betFancyPosition +
        `?eventId=${parmas.eventId}&type=${details.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        // secureLocalStorage.setItem(
        //   "fancy",
        //   response_users?.results?.map((res) => {
        //     return { selectionId: res?.selectionId, position: res?.position };
        //   }) || []
        // );
        updatedFancyPosition(
          response_users?.results?.map((res) => {
            return { selectionId: res?.selectionId, position: res?.position };
          }) || []
        );
      }
    }
  };
  const getPremiumFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPremiumFancyPosition +
        `?eventId=${parmas.eventId}&type=${details.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        setPremiumFancyPosition(response_users?.results);
        // secureLocalStorage.setItem(
        //   "premiumFancy",
        //   response_users?.results || []
        // );
        setPremiumFancy((current) => {
          return current?.map((res) => {
            let obj = response_users?.results?.filter((item) => {
              return item?.fancyName == res?.marketName;
            });
            if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                  let temp = obj?.find(
                    (item) => elem?.id == item?.fancySelectionId
                  );
                  return {
                    ...elem,
                    position: temp?.position,
                  };
                }),
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };
  const updatePositionAfterBetPlace = (type) => {
    if (type == "betFair") {
      // secureLocalStorage?.removeItem("betFair");
      // secureLocalStorage.setItem(
      //   "betFair",
      //   selections?.map((res) => {
      //     return {
      //       selectionId: res?.SelectionId,
      //       position: res?.newPosition,
      //     };
      //   })
      // );
      setPosition((prev) => {
        return {
          ...prev,
          betFair: selections?.map((res) => {
            return {
              selectionId: res?.SelectionId,
              position: res?.newPosition,
            };
          }),
        };
      });
      setSelections(
        selections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
      setSelectionDemo(
        selections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "fancy") {
      // secureLocalStorage?.removeItem("fancy");
      // secureLocalStorage.setItem(
      //   "fancy",
      //   fancyList
      //     ?.filter((res) => {
      //       return (
      //         Math.abs(res?.position) > 0 || res?.selectionId == slipObj?.selectionId
      //       );
      //     })
      //     ?.map((res) => {
      //       if(res?.selectionId == slipObj?.selectionId){
      //         let newPosition = ''
      //         if (slipObj?.ib) {
      //           if (Math.abs(res?.position) > 0) {
      //             let backLoss = Number(betSelectionObj?.bidPrice) + Number(res.position);
      //             newPosition = Number(backLoss);
      //           } else {
      //             let backLoss = Number(betSelectionObj?.bidPrice);
      //             newPosition = Number(backLoss);
      //           }
      //         } else {
      //           if (Math.abs(res?.position) > 0) {
      //             let backProfit =
      //               (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
      //               newPosition = (Number(backProfit) + Number(res.position));
      //           } else {
      //             let backProfit =
      //               (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
      //             newPosition = (Number(backProfit));
      //           }
      //         }
      //         return {
      //           selectionId: res?.selectionId,
      //           position: newPosition,
      //         };
      //       } else {
      //         return {
      //           selectionId: res?.selectionId,
      //           position: res?.position,
      //         };
      //       }
      //     })
      // );
      // setFancyList((prev) => {
      //   return prev?.map((res) => {
      //     if(res?.selectionId == slipObj?.selectionId){
      //       let newPosition = ''
      //       if (slipObj?.ib) {
      //         if (Math.abs(res?.position) > 0) {
      //           let backLoss = Number(betSelectionObj?.bidPrice) + Number(res.position);
      //           newPosition = Number(backLoss);
      //         } else {
      //           let backLoss = Number(betSelectionObj?.bidPrice);
      //           newPosition = Number(backLoss);
      //         }
      //       } else {
      //         if (Math.abs(res?.position) > 0) {
      //           let backProfit =
      //             (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
      //             newPosition = (Number(backProfit) + Number(res.position));
      //         } else {
      //           let backProfit =
      //             (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
      //           newPosition = (Number(backProfit));
      //         }
      //       }
      //       return {
      //        ...res,
      //         position: newPosition
      //       };
      //     } else {
      //     return {
      //       ...res,
      //       position:res?.position
      //     }}
      //   });
      // });
      // setFancyCategoryTabArray((prev) => {
      //   return prev?.map((res) => {
      //     if(res?.selectionId == slipObj?.selectionId){
      //       let newPosition = ''
      //       if (slipObj?.ib) {
      //         if (Math.abs(res?.position) > 0) {
      //           let backLoss = Number(betSelectionObj?.bidPrice) + Number(res.position);
      //           newPosition = Number(backLoss);
      //         } else {
      //           let backLoss = Number(betSelectionObj?.bidPrice);
      //           newPosition = Number(backLoss);
      //         }
      //       } else {
      //         if (Math.abs(res?.position) > 0) {
      //           let backProfit =
      //             (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
      //             newPosition = (Number(backProfit) + Number(res.position));
      //         } else {
      //           let backProfit =
      //             (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
      //           newPosition = (Number(backProfit));
      //         }
      //       }
      //       return {
      //        ...res,
      //         position: newPosition
      //       };
      //     } else {
      //     return {
      //       ...res,
      //       position:res?.position
      //     }}
      //   });
      // });
    } else if (type == "bookmaker") {
      // secureLocalStorage?.removeItem("bookmaker");
      // secureLocalStorage.setItem(
      //   "bookmaker",
      //   bookmakerSelections?.map((res) => {
      //     return {
      //       selectionId: `B${res?.SelectionId}`,
      //       position: res?.newPosition,
      //     };
      //   })
      // );
      setPosition((prev) => {
        return {
          ...prev,
          bookmaker: bookmakerSelections?.map((res) => {
            return {
              selectionId: `B${res?.SelectionId}`,
              position: res?.newPosition,
            };
          }),
        };
      });
      setBookmakerSelections(
        bookmakerSelections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "tie") {
      // secureLocalStorage?.removeItem("tie");
      // secureLocalStorage.setItem(
      //   "tie",
      //   jsonTieData?.map((res) => {
      //     return {
      //       selectionId: res?.SelectionId,
      //       position: res?.newPosition,
      //     };
      //   })
      // );
      setJsonTieData(
        jsonTieData?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "toss") {
      // secureLocalStorage?.removeItem("toss");
      // secureLocalStorage.setItem(
      //   "toss",
      //   tossList?.map((res) => {
      //     return {
      //       selectionId: res?.SelectionId,
      //       position: res?.newPosition,
      //     };
      //   })
      // );
      setTossList(
        tossList?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      if (betPlacedCheck && isBetPlaced == "placed") {
        if (betSelectionObj?.betType == "fancy") {
          getFancyBetPosition();
          // updatePositionAfterBetPlace(betSelectionObj?.betType)
          setBetPlacedCheck(false);
        } else if (
          betSelectionObj?.betType == "bookmaker" ||
          betSelectionObj?.betType == "betFair" ||
          betSelectionObj?.betType == "toss" ||
          betSelectionObj?.betType == "tie"
        ) {
          getBetPosition();
          // updatePositionAfterBetPlace(betSelectionObj?.betType);
          setBetPlacedCheck(false);
        } else if (betSelectionObj?.betType == "premiumFancy") {
          getPremiumFancyBetPosition();
          setBetPlacedCheck(false);
        }
      } else if (betPlacedCheck && isBetPlaced == "error") {
        resetSelection(betSelectionObj?.betType);
      }
    }
  }, [betPlacedCheck, isBetPlaced, updatePosition]);

  const resetPosition = (type) => {
    if (type == "betFair") {
      setSelections((current) =>
        position?.betFair?.length > 0
          ? current?.map((res) => {
              let obj = position?.betFair?.find(
                (item) => item.selectionId == res.SelectionId
              );
              if (!isEmpty(obj)) {
                return {
                  ...res,
                  position:
                    obj.selectionId == res.SelectionId ? obj?.position : 0,
                  newPosition: 0,
                };
              } else {
                return {
                  ...res,
                  position: 0,
                  newPosition: 0,
                };
              }
            })
          : current.map((res) => {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            })
      );
    } else if (type == "bookmaker") {
      setBookmakerSelections((current) =>
        position?.bookmaker?.length > 0
          ? current?.map((res) => {
              let obj = position?.bookmaker?.find(
                (item) => item?.selectionId == `B${res?.SelectionId}`
              );
              return {
                ...res,
                position: obj?.position ? obj?.position : 0,
                newPosition: 0,
              };
            })
          : current?.map((res) => {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            })
      );
    } else if (type == "sportBook") {
      setPremiumFancy((current) => {
        return premiumFancyPosition?.length > 0
          ? current?.map((res) => {
              let obj = premiumFancyPosition?.find(
                (item) =>
                  item?.fancyName == res?.marketName &&
                  res?.id == item?.marketId
              );
              return obj?.marketId == res?.id &&
                obj?.fancyName == res?.marketName
                ? {
                    ...res,
                    sportsBookSelection: res?.sportsBookSelection?.map(
                      (temp) => {
                        return temp?.id == obj?.selectionId &&
                          temp?.selectionName == obj?.runnerName
                          ? {
                              ...temp,
                              position: obj?.profitAmount,
                              newPosition: 0,
                            }
                          : {
                              ...temp,
                              position: obj?.loseAmount,
                              newPosition: 0,
                            };
                      }
                    ),
                  }
                : res;
            })
          : current.map((res) => {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((item) => {
                  return { ...item, newPosition: 0 };
                }),
              };
            });
      });
    } else if (type == "toss") {
      setTossList((current) =>
        current.map((res) => {
          return {
            ...res,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: newObj?.position || 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      let obj = (
        details?.matchSetting?.length > 0
          ? details?.matchSetting
          : profileData?.matchSetting
      )?.find(
        (res) =>
          res?.type == "sportBook" &&
          (details?.matchSetting?.length > 0
            ? details?.matchSetting
            : profileData?.matchSetting
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(obj)) {
        setMinSportBook(Number(obj?.minAmount));
      }
    }
  }, []);

  const resetSelection = (type) => {
    if (type == "betFair") {
      setSelectionDemo((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
      setSelections((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      setBookmakerSelections((current) =>
        current?.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "premiumFancy") {
      setPremiumFancy((current) => {
        return premiumFancyPosition?.length > 0
          ? current?.map((res) => {
              let obj = premiumFancyPosition?.find(
                (item) =>
                  item?.fancyName == res?.marketName &&
                  res?.id == item?.marketId
              );
              return obj?.marketId == res?.id &&
                obj?.fancyName == res?.marketName
                ? {
                    ...res,
                    sportsBookSelection: res?.sportsBookSelection?.map(
                      (temp) => {
                        return temp?.id == obj?.selectionId &&
                          temp?.selectionName == obj?.runnerName
                          ? {
                              ...temp,
                              position: obj?.profitAmount,
                              newPosition: 0,
                            }
                          : {
                              ...temp,
                              position: obj?.loseAmount,
                              newPosition: 0,
                            };
                      }
                    ),
                  }
                : res;
            })
          : current;
      });
    } else if (type == "tie") {
      setJsonTieData((current) =>
        current.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        })
      );
    } else if (type == "toss") {
      setTossList((prev) => {
        return prev.map((res) => {
          return {
            ...res,
            position: Math.abs(res?.position) > 0 ? res?.position : 0,
            newPosition: 0,
          };
        });
      });
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: Math.abs(newObj?.position) > 0 ? newObj?.position : 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
  };

  const handelBetFairPositions = (slipObj) => {
    let newselection = [...selections]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    let selectionsTemp = [...newselection];
    if (betSelectionObj?.bidPrice > 0) {
      if (slipObj.ib) {
        let backProfit =
          Number(betSelectionObj.oddsSelect - 1) *
          Number(betSelectionObj?.bidPrice); //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
        let backLoss = Number(betSelectionObj?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSelectionObj?.bidPrice);
        let backLoss =
          Number(betSelectionObj.oddsSelect - 1) *
          Number(betSelectionObj?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  const handelBookmakerPositions = (slipObj) => {
    let newselection = [...bookmakerSelections]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (betSelectionObj?.bidPrice >= 0) {
      if (slipObj.ib) {
        let backProfit = Number(
          (Number(slipObj?.rt) / 100) * Number(betSelectionObj?.bidPrice)
        );
        let backLoss = Number(betSelectionObj?.bidPrice);
        if (Math.abs(newselection[0]?.position) > 0) {
          setBookmakerSelections(
            newselection?.map((rl) => {
              if (rl.SelectionId == slipObj.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setBookmakerSelections(
            newselection?.map((rl) => {
              if (rl.SelectionId == slipObj.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSelectionObj?.bidPrice);
        let backLoss = Number(
          (Number(slipObj?.rt) / 100) * Number(betSelectionObj?.bidPrice)
        );
        if (Math.abs(newselection[0]?.position) > 0) {
          setBookmakerSelections(
            newselection?.map((rl) => {
              if (rl.SelectionId == slipObj.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setBookmakerSelections(
            newselection?.map((rl) => {
              if (rl.SelectionId == slipObj.SelectionId) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  const handelFancyPositions = (slipObj) => {
    if (betSelectionObj?.bidPrice >= 0) {
      if (slipObj.ib) {
        let currentPosition = fancyList.find(
          (rl) => rl.selectionId == slipObj.selectionId
        );
        if (
          Math.abs(currentPosition.position) !== 0 &&
          !isEmpty(currentPosition.type)
        ) {
          let backLoss = betSelectionObj?.bidPrice + currentPosition.position;
          currentPosition.newPosition = backLoss;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        } else {
          let backLoss = betSelectionObj?.bidPrice;
          currentPosition.newPosition = backLoss;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        }
      } else {
        let currentPosition = fancyList.find(
          (rl) => rl?.selectionId == slipObj?.selectionId
        );
        if (
          !isNaN(Math.abs(currentPosition?.position)) &&
          !isEmpty(currentPosition?.type)
        ) {
          let backProfit =
            (betSelectionObj?.bidPrice * betSelectionObj.fancyOddSelect) / 100;
          currentPosition.newPosition = backProfit + currentPosition.position;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        } else {
          let backProfit =
            (betSelectionObj?.bidPrice * betSelectionObj.fancyOddSelect) / 100;
          currentPosition.newPosition = backProfit;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          // console.log("result",result);

          setFancyCategoryTabArray(result);
          setFancyList(result);
        }
      }
    }
  };

  const handelPreminumFancyPositions = (slipObj) => {
    if (betSelectionObj?.bidPrice >= 0) {
      let backProfit =
        (betSelectionObj.oddsSelect - 1) * betSelectionObj?.bidPrice;
      let backLoss = betSelectionObj?.bidPrice;
      setPremiumFancy((current) => {
        return current?.map((rl) =>
          rl?.id === slipObj?.newMarketId
            ? {
                ...rl,
                sportsBookSelection: rl?.sportsBookSelection?.map((res) => {
                  return res.id === slipObj.selectId
                    ? {
                        ...res,
                        position:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? res.position
                            : 0,
                        newPosition:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? Number(backProfit) + Number(res.position)
                            : Number(backProfit),
                      }
                    : {
                        ...res,
                        position:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? res.position
                            : 0,
                        newPosition:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? Number(backLoss) - Number(res.position)
                            : -Number(backLoss),
                      };
                }),
              }
            : rl
        );
      });
      // setPremiumFancy((current) => {
      //   return current?.map((rl) =>
      //     rl.marketId === slipObj.marketId
      //       ? {
      //           ...rl,
      //           runners: rl.runners?.map((res) => {
      //             return res.runnerId === slipObj.selectId
      //               ? {
      //                   ...res,
      //                   position:
      //                     res.position !== 0 && !isNaN(Math.abs(res.position))
      //                       ? res.position
      //                       : 0,
      //                   newPosition:
      //                     res.position !== 0 && !isNaN(Math.abs(res.position))
      //                       ? Number(backProfit) + Number(res.position)
      //                       : Number(backProfit),
      //                 }
      //               : {
      //                   ...res,
      //                   position:
      //                     res.position !== 0 && !isNaN(Math.abs(res.position))
      //                       ? res.position
      //                       : 0,
      //                   newPosition:
      //                     res.position !== 0 && !isNaN(Math.abs(res.position))
      //                       ? Number(backLoss) - Number(res.position)
      //                       : -Number(backLoss),
      //                 };
      //           }),
      //         }
      //       : rl
      //   );
      // });
    }
  };

  const tossPosition = (slipObj) => {
    if (betSelectionObj?.bidPrice >= 0) {
      let temp = [...tossList].map((res) => {
        return {
          ...res,
          newPosition: res?.newPosition || 0,
          position: res?.position || 0,
        };
      });
      let backProfit =
        Number(betSelectionObj.oddsSelect - 1) *
        Number(betSelectionObj?.bidPrice); //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
      let backLoss = Number(betSelectionObj?.bidPrice);
      if (Math.abs(temp[0]?.position) > 0) {
        setTossList(
          temp?.map((res) => {
            if (res.SelectionId == slipObj.SelectionId) {
              return {
                ...res,
                newPosition: Number(res.position) + backProfit,
              };
            } else {
              return {
                ...res,
                newPosition: Number(res.position) - backLoss,
              };
            }
          })
        );
      } else {
        setSelections(
          temp?.map((rl) => {
            if (rl.SelectionId == slipObj.SelectionId) {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            } else {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            }
          })
        );
      }
    }
  };

  const handelTiePosition = (slipObj) => {
    let temp = [...jsonTieData];
    if (betSelectionObj?.bidPrice > 0) {
      if (slipObj.ib) {
        let currentPosition = temp.find((rl) => rl.SelectionId == slipObj.ri);
        let otherTeamPosition;
        let thirdTeamPosition;
        if (slipObj.RunnerName == "The Draw") {
          otherTeamPosition = temp[0];
          thirdTeamPosition = temp[1];
        } else {
          otherTeamPosition = temp.find(
            (rl) => rl.SelectionId !== slipObj.ri && rl.RunnerName != "The Draw"
          );
          thirdTeamPosition = temp[2] ? temp[2] : false;
        }
        let backProfit =
          (betSelectionObj.oddsSelect - 1) * betSelectionObj?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
        let backLoss = betSelectionObj?.bidPrice;
        if (
          Math.abs(temp[0]?.position) !== 0 &&
          Math.abs(temp[1]?.position) !== 0
        ) {
          currentPosition.newPosition = currentPosition.position + backProfit;
          otherTeamPosition.newPosition = otherTeamPosition.position - backLoss;
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition =
              thirdTeamPosition.position - backLoss;
          }
          const result = temp.map((data) => {
            if (data.SelectionId == currentPosition.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (data.SelectionId == otherTeamPosition.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (
              thirdTeamPosition &&
              data.SelectionId == thirdTeamPosition.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            return data;
          });
          setJsonTieData(result);
        } else {
          currentPosition.newPosition = backProfit;
          otherTeamPosition.newPosition = -backLoss;
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition = -backLoss;
          }
          const result = temp.map((data) => {
            if (data.SelectionId == currentPosition.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (data.SelectionId == otherTeamPosition.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (
              thirdTeamPosition &&
              data.SelectionId == thirdTeamPosition.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            return data;
          });
          setJsonTieData(result);
        }
      } else {
        let currentPosition = temp.find((rl) => rl.SelectionId == slipObj.ri);
        let otherTeamPosition;
        let thirdTeamPosition;
        if (slipObj.RunnerName == "The Draw") {
          otherTeamPosition = temp[0];
          thirdTeamPosition = temp[1];
        } else {
          otherTeamPosition = temp.find(
            (rl) => rl.SelectionId !== slipObj.ri && rl.RunnerName != "The Draw"
          );
          thirdTeamPosition = temp[2] ? temp[2] : false;
        }

        if (
          Math.abs(currentPosition?.position) !== 0 &&
          Math.abs(currentPosition?.position) !== 0
        ) {
          let backProfit = betSelectionObj?.bidPrice;
          let backLoss =
            (betSelectionObj.oddsSelect - 1) * betSelectionObj?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
          currentPosition.newPosition =
            Number(currentPosition?.position) - Number(backLoss);
          otherTeamPosition.newPosition =
            Number(otherTeamPosition?.position) + Number(backProfit);
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition =
              Number(thirdTeamPosition?.position) + Number(backProfit);
          }
          const result = temp.map((data) => {
            if (data?.SelectionId == currentPosition?.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition)
                : 0;
            }
            if (data?.SelectionId == otherTeamPosition?.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition)
                : 0;
            }
            if (
              thirdTeamPosition &&
              data?.SelectionId == thirdTeamPosition?.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition)
                : 0;
            }
            return data;
          });
          setJsonTieData(result);
        } else {
          let backProfit = betSelectionObj?.bidPrice;
          let backLoss =
            (betSelectionObj.oddsSelect - 1) * betSelectionObj?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice
          currentPosition.newPosition = -Number(backLoss);
          otherTeamPosition.newPosition = Number(backProfit);
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition = Number(backProfit);
          }
          const result = temp.map((data) => {
            if (data?.SelectionId == currentPosition?.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition)
                : 0;
            }
            if (data?.SelectionId == otherTeamPosition?.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition)
                : 0;
            }
            if (
              thirdTeamPosition &&
              data?.SelectionId == thirdTeamPosition?.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition)
                : 0;
            }
            return data;
          });
          setJsonTieData(result);
        }
      }
    }
  };

  const updatedFancyPosition = (object) => {
    setFancyPosition(object);
    setFancyList((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
    setFancyCategoryTabArray((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
  };

  const updatePositionNew = (object) => {
    setTempSelection(object?.betFair);
    setSelectionDemo((current) =>
      object?.betFair?.length > 0
        ? current.map((res) => {
            let obj = object?.betFair.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setSelections((current) =>
      object?.betFair?.length > 0
        ? current.map((res) => {
            let obj = object?.betFair.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setBookmakerSelections((current) =>
      object?.bookmaker?.length > 0
        ? current?.map((res) => {
            let obj = object?.bookmaker?.find(
              (item) => item?.selectionId == `B${res?.SelectionId}`
            );
            return {
              ...res,
              position: obj?.position ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setJsonTieData((current) =>
      object?.tie?.length > 0
        ? current?.map((res) => {
            let obj = object?.tie?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            return {
              ...res,
              position:
                obj?.selectionId == res?.SelectionId ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setTossList((current) =>
      object?.toss?.length > 0
        ? current?.map((res) => {
            let obj = object?.toss?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            return {
              ...res,
              position:
                obj?.selectionId == res?.SelectionId ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!isEmpty(user)) {
  //       if (!isEmpty(details)) {
  //         startTransition(() => {
  //           if (
  //             !(
  //               secureLocalStorage.getItem("betFair") ||
  //               secureLocalStorage.getItem("bookmaker")
  //             )
  //           ) {
  //             getBetPosition();
  //           } else {
  //             let obj = {
  //               betFair:
  //                 secureLocalStorage.getItem("betFair")?.length > 0
  //                   ? secureLocalStorage.getItem("betFair")
  //                   : [],
  //               bookmaker:
  //                 secureLocalStorage.getItem("bookmaker")?.length > 0
  //                   ? secureLocalStorage.getItem("bookmaker")
  //                   : [],
  //               tie:
  //                 secureLocalStorage.getItem("tie")?.length > 0
  //                   ? secureLocalStorage.getItem("tie")
  //                   : [],
  //               toss:
  //                 secureLocalStorage.getItem("toss")?.length > 0
  //                   ? secureLocalStorage.getItem("toss")
  //                   : [],
  //             };
  //             updatePositionNew(obj);
  //           }
  //           getFancyBetPosition();
  //           if (!secureLocalStorage.getItem("premiumFancy")) {
  //             getPremiumFancyBetPosition();
  //           }
  //         });
  //       }
  //     }
  //   }, 2000);
  // }, [details]);

  useEffect(() => {
    setTimeout(() => {
      if (!isEmpty(user)) {
        if (!isEmpty(details)) {
          startTransition(() => {
            getBetPosition();
            getFancyBetPosition();
            if (details?.premiumFancy == "on") {
              getPremiumFancyBetPosition();
            }
          });
        }
      }
    }, 500);
  }, [details]);

  useEffect(() => {
    if (
      !isEmpty(slipObj) &&
      betSelectionObj?.betType == "betFair" &&
      betSelectionObj?.bidPrice > 0
    ) {
      handelBetFairPositions(slipObj);
    } else if (
      !isEmpty(slipObj) &&
      betSelectionObj?.betType == "bookmaker" &&
      betSelectionObj?.bidPrice > 0
    ) {
      handelBookmakerPositions(slipObj);
    } else if (
      !isEmpty(slipObj) &&
      betSelectionObj?.betType == "fancy" &&
      betSelectionObj?.bidPrice > 0
    ) {
      handelFancyPositions(slipObj);
    } else if (
      !isEmpty(slipObj) &&
      betSelectionObj?.betType == "premiumFancy" &&
      betSelectionObj?.bidPrice > 0
    ) {
      handelPreminumFancyPositions(slipObj);
    } else if (
      !isEmpty(slipObj) &&
      betSelectionObj?.priceToogleType == "toss" &&
      betSelectionObj?.betType == "toss" &&
      betSelectionObj?.bidPrice > 0
    ) {
      tossPosition(slipObj);
    } else if (
      !isEmpty(slipObj) &&
      betSelectionObj?.priceToogleType == "odds" &&
      betSelectionObj?.betType == "tie" &&
      betSelectionObj?.bidPrice > 0
    ) {
      handelTiePosition(slipObj);
    }
  }, [
    betSelectionObj?.bidPrice,
    slipObj,
    betSelectionObj?.oddsSelect,
    betSelectionObj?.betType,
  ]);

  let header1 = document.getElementById("headerMain1")
    ? Number(document.getElementById("headerMain1")?.offsetHeight)
    : 0;
  let header2 = document.getElementById("headerMain2")
    ? Number(document.getElementById("headerMain2")?.offsetHeight)
    : 0;
  let videoFrame = document?.getElementById("videoFrame")?.offsetHeight
    ? Number(document?.getElementById("videoFrame")?.offsetHeight)
    : 0;

  useEffect(() => {
    const check = () => {
      if (pop && y >= 280 && isTv?.status && isTv?.id !== "") {
        return header1 + header2 + videoFrame;
      } else if (isTv?.status && isTv?.id !== "") {
        return header1 + header2 + videoFrame;
      } else {
        return header1 + header2;
      }
    };

    let temp = check();
    if (!isEmpty(user)) {
      var allElements = document.getElementsByTagName("*");
      for (var i = 0, len = allElements.length; i < len; i++) {
        var element = allElements[i];
        element.style.scrollMarginTop = `${temp}px`;
      }
    }
    if (key == "Fancy Bet") {
      if (
        fancyCategoryTabArray?.filter((res) => res.odds?.rt?.length > 0)
          ?.length > 7
      ) {
        setPop(true);
      } else {
        setPop(false);
      }
    } else if (key == "Preminum Fancy") {
      if (preminumFancy?.length >= 12) {
        setPop(true);
      } else {
        setPop(false);
      }
    }
  }, [y, key, details, preminumFancy, fancyList, betSelectionObj]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  let timeDiff = moment.duration(
    moment(details.eventDateTime).diff(moment(new Date()))
  )?._data;
  let temp =
    timeDiff?.days <= 0 &&
    timeDiff?.hours <= 1 &&
    timeDiff?.years <= 0 &&
    timeDiff?.months <= 0 &&
    timeDiff?.minutes <= 5;

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setControls(false);
    }, 4000);
    return () => window.clearTimeout(timeoutID);
  }, [controls]);

  useEffect(() => {
    if (isTv?.status) {
      setControls(true);
    }
  }, [isTv]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInplaySlip(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [details?.status, totalMatched]);
  const [topTab, setTopTab] = useState("scoreboard");



  return (
    <>
      <div id="mainWrap" className="mainWrap">
      {!isEmpty(user) && details?.status == "in_play" && (
            <div class="topnav">
              <p
                onClick={() => setTopTab("scoreboard")}
                class={topTab === "scoreboard" ? "active" : ""}
              >
                ScoreBoard
              </p>
              <p
                onClick={() => setTopTab("live")}
                class={topTab === "live" ? "active" : ""}
              >
                Live
              </p>

            </div>
          )}
        {!isEmpty(user) && details?.status == "in_play" && temp && topTab === "live" ? (
          isTv?.id !== "false" && isTv?.status ? (
            <div
              className={
                pop && y >= 280 ? "is-outside vedio_wrap" : "vedio_wrap"
              }
            >
              {controls && (
                <div className="tv-control vedio_up_icon">
                  <a
                    class="close"
                    id="closeStreamingBox"
                    href="javascript:void(0)"
                    onClick={() => {
                      setIsTv({ ...isTv, status: false });
                      setSeek(true);
                    }}
                    style={{ display: "flex" }}
                  >
                    Close
                  </a>
                </div>
              )}
              {pop && y >= 280
                ? ""
                : controls && (
                    <div
                      className="tv-control vedio_up_icon"
                      style={{ marginBottom: "20px" }}
                    >
                      <a
                        class={mute ? "btn-volume_off" : "btn-volume_on"}
                        onClick={() => setMute(!mute)}
                        href="javascript:void(0)"
                        id="mute"
                      >
                        Close
                      </a>
                      <a
                        className="btn-line"
                        href="javascript:void(0)"
                        id="changeLineBtn"
                      >
                        <span id="tvLine">4</span>
                      </a>
                    </div>
                  )}
              {ref?.current?.getSecondsLoaded() == null ||
              ref?.current?.getSecondsLoaded() == 0 ? (
                <div
                  className="tv-control live_img"
                  style={{
                    padding: "0",
                    margin: "0",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={LoadingImage}
                    alt="Thumbnail"
                    onClick={() => setControls(true)}
                    style={{
                      overflowY: "hidden",
                      height: "auto",
                      width: "100%",
                      overflowX: "hidden",
                      overflowY: "hidden",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {checkTvRefresh && (
                <ReactPlayer
                  muted={mute}
                  controls={false}
                  ref={ref}
                  playsinline={true}
                  loop={true}
                  playing={true}
                  id="videoFrame"
                  style={{
                    overflowX: "hidden",
                    overflow: "hidden",
                    backgroundColor: "#000000",
                  }}
                  onClick={() => setControls(true)}
                  width="100%"
                  height="auto"
                  url={
                    details?.adsStatus
                      ? details?.adsContent
                      : `https://mediasrv789-ss247-23-prod-sa-ulivestreaming.com/${details.channel}/index.m3u8`
                  }
                  config={{
                    file: {
                      attributes: {
                        preload: "none",
                        //forceHLS: true,
                      },
                      hlsOptions: {
                        //autoStartLoad: false,
                        startLevel: 3,
                      },
                    },
                  }}
                />
              )}
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className="game-wrap">
          <h4 id="gameInfo" class="game-info">
            {obj?.matchType &&
              details?.gameType &&
              obj?.matchType[details?.gameType] && (
                <>
                  <span>{obj?.matchType[details?.gameType]}</span>
                  <ul id="infoIcon" class="info-icon">
                    {showInplaySlip ? (
                      <>
                        {details?.status == "in_play" &&
                        totalMatched > 10000 ? (
                          <li id="inPlayIcon">
                            <img
                              className="info-inplay"
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                "/assets/images/home/transparent.gif"
                              }
                            />
                            In-Play
                          </li>
                        ) : (
                          <li id="inQuality">
                            {totalMatched < 10000 ? "Low Liquidity" : ""}
                          </li>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </>
              )}
          </h4>

          { topTab === "scoreboard"  && !isEmpty(details?.scoreURL) ? (
            <div id="matchTrackerWrap" className="match-tracker-wrap">
              <iframe
                className="responsive-iframe w-100"
                style={{
                  overflow: "hidden",
                  height: "180px",
                  backgroundColor: "#000000",
                }}
                id="scoreId"
                src={details?.scoreURL}
              ></iframe>
              <ul class="match-btn" style={{ paddingBottom: "10px" }}>
                <li>
                  <a
                    onClick={() => {
                      if (isEmpty(user)) {
                        navigate("/login");
                      } else {
                        handelAddMultiMarket(parmas?.eventId);
                      }
                    }}
                    id="liveMultiMarketPin"
                    href="javascript:void(0)"
                    className={
                      !isEmpty(details?.multi_market)
                        ? "btn-pin select"
                        : "btn-pin"
                    }
                  >
                    Pin
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    class="btn-refresh"
                    onClick={() => {
                      getMatchDetails();
                    }}
                  >
                    Refresh
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <table
                id="gameTeam"
                class="game-team"
                style={{ background: "#172832", color: "white" }}
              >
                <tbody>
                  <tr>
                    <th>
                      <a
                        onClick={() => {
                          if (isEmpty(user)) {
                            navigate("/login");
                          } else {
                            handelAddMultiMarket(parmas?.eventId);
                          }
                        }}
                        id="multiMarketPin"
                        className={
                          !isEmpty(details?.multi_market) ? "pin-on" : "pin-off"
                        }
                        href="javascript:void(0)"
                      ></a>
                      <h4 id="teamHome">{details?.eventName?.split("v")[0]}</h4>
                      <h4 id="teamAway">{details?.eventName?.split("v")[1]}</h4>
                      <ul id="time" class="scores-time">
                        {temp.days == 0 &&
                        temp.hours == 0 &&
                        temp?.years == 0 &&
                        temp?.months == 0 &&
                        temp.minutes > 0 &&
                        temp?.minutes < profileData?.beforeInPlay ? (
                          <span id="dateTimeInfo" className="time">
                            Starting in {temp?.minutes}'
                          </span>
                        ) : details.status == "in_play" ? (
                          <span id="dateTimeInfo" className="time">
                            {helpers.matchDateTime(details.eventDateTime)}
                          </span>
                        ) : (
                          <span id="dateTimeInfo" className="time">
                            {helpers.matchDateTime(details.eventDateTime)}
                          </span>
                        )}
                      </ul>
                    </th>
                    <td class="team-refresh">
                      <a
                        id="refresh"
                        class="refresh"
                        href="javascript:void(0)"
                        onClick={() => {
                          getMatchDetails();
                        }}
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              {details?.isDiamondScore && tScore && (
                <>
                  <div id="scoreboard">
                    <div className="highlight">{tScore.spnmessage}</div>
                    <div className="row">
                      <div className="left">
                        <span className="team-name">{tScore.spnnation1}:</span>{" "}
                        <span className="team-score">{tScore.score1}</span>
                      </div>
                      <div className="right">
                        <span className="team-name">{tScore.spnnation2}:</span>{" "}
                        <span className="team-score">{tScore.score2}</span>
                      </div>
                    </div>
                    {tScore?.spnrunrate1 && (
                      <div className="row">
                        <div className="left">
                          <span id="runRate">
                            {tScore?.spnrunrate1 ? tScore?.spnrunrate1 : ""}
                          </span>
                        </div>
                        {tScore.balls && tScore.balls.length > 0 && (
                          <div className="right">
                            <span id="runsInCurrentOver">
                              <span className="circle">{tScore.balls[0]}</span>
                              <span className="circle">{tScore.balls[1]}</span>
                              <span className="circle">{tScore.balls[2]}</span>
                              <span className="circle">{tScore.balls[3]}</span>
                              <span className="circle">{tScore.balls[4]}</span>
                              <span className="circle">{tScore.balls[5]}</span>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {!parly ? (
          <>
            {details?.matchOdds == "on" &&
              (selections?.length > 0 || marketNew?.length > 0) &&
              back_odds?.length > 0 &&
              back_odds?.length > 0 && (
                <div
                  id="naviMarket"
                  className="market-type ps ps--theme_default"
                >
                  <ul id="naviMarketList">
                    {marketNew?.length > 0
                      ? marketNew?.map((res) => {
                          return (
                            <li
                              className={
                                res?.marketId === parmas?.marketId
                                  ? "select"
                                  : ""
                              }
                              onClick={() => {
                                console.log(res?.marketId,'res?.marketId')
                                navigate(
                                  `/match-details/${parmas?.eventId}/${res?.marketId}`
                                );
                                setSelections(res?.jsonData || []);
                                setTempSelection(res?.jsonData || []);
                                getMatchData(res?.marketId);
                                getBetPosition(res?.marketId);
                                setMarketName(res?.market)
                              }}
                            >
                              <a href="#">{res?.market}</a>
                            </li>
                          );
                        })
                      : selections?.length > 0 &&
                        back_odds?.length > 0 &&
                        back_odds?.length > 0 && (
                          <li
                            id="naviMarket_1_207539177"
                            onClick={() => {
                              setTab("match");
                              resetSelection("tie");
                              setBetSelectionObj({});
                              setSlipObj({});
                            }}
                            className={tab === "match" ? "select" : ""}
                          >
                            <a href="#">Match Odds</a>
                          </li>
                        )}
                    {/* {jsonTieData?.length > 0 && (
                      <li
                        id="naviMarket_1_207539177"
                        onClick={() => {
                          setTab("tie");
                          resetSelection("betFair");
                          setBetSelectionObj({});
                          setSlipObj({});
                        }}
                        className={tab === "tie" ? "select" : ""}
                      >
                        <a href="#">Tie Match</a>
                      </li>
                    )} */}
                  </ul>
                </div>
              )}
            {details?.matchOdds == "on" &&
              selections?.length > 0 &&
              back_odds?.length > 0 &&
              back_odds.filter((el) => el?.rt !== 0)?.length > 0 &&
              lay_odds.filter((el) => el?.rt !== 0)?.length > 0 && (
                <MatchDetails
                  selections={selections}
                  back_odds={back_odds}
                  betFairMs={betFairMs}
                  lay_odds={lay_odds}
                  messaageBox={messaageBox}
                  details={details}
                  totalMatched={totalMatched}
                  setSlipObj={setSlipObj}
                  setMarketDepth={setMarketDepth}
                  marketName={marketName}
                  tempSelection={tempSelection}
                  setSelections={setSelections}
                  slipObj={slipObj}
                  user={user}
                  loader={loader}
                  setLoader={setLoader}
                  betType={"betFair"}
                  handelBetFairPositions={
                     handelBetFairPositions
                  }
                  marketId={parmas?.marketId}
                  resetSelection={resetSelection}
                  setCondi={setCondi}
                  profileData={
                    details?.matchSetting?.length > 0
                      ? { ...details, arrayCheck: "details" }
                      : { ...profileData, arrayCheck: "profile" }
                  }
                />
              )}

            {/* {marketNew?.map((res) => {
              return (
                <>
                  <div
                    id="naviMarket"
                    className="market-type ps ps--theme_default"
                  >
                    <ul id="naviMarketList">
                      <li
                        id="naviMarket_1_207539177"
                        onClick={() => {
                          setTab("match");
                          resetSelection("tie");
                          setBetSelectionObj({});
                          setSlipObj({});
                        }}
                        className={tab === "match" ? "select" : ""}
                      >
                        <a href="#">{res?.market}</a>
                      </li>
                    </ul>
                  </div>
                  <MatchDetailNew
                    selections={res?.jsonData}
                    back_odds={[]}
                    // betFairMs={betFairMs}
                    lay_odds={[]}
                    messaageBox={messaageBox}
                    details={details}
                    totalMatched={totalMatched}
                    setSlipObj={setSlipObj}
                    setMarketDepth={setMarketDepth}
                    tempSelection={tempSelection}
                    setSelections={setSelections}
                    slipObj={slipObj}
                    user={user}
                    loader={loader}
                    setLoader={setLoader}
                    betType={"betFair"}
                    handelBetFairPositions={handelBetFairPositions}
                    resetSelection={resetSelection}
                    setCondi={setCondi}
                    profileData={
                      details?.matchSetting?.length > 0
                        ? { ...details, arrayCheck: "details" }
                        : { ...profileData, arrayCheck: "profile" }
                    }
                  />
                </>
              );
            })} */}

            {details?.bookMaker == "on" && details?.gameType == "cricket" && (
              <Bookmaker
                betType="bookmaker"
                data={bookmakerSelections}
                bookmakerObj={bookmakerData}
                details={details}
                back_odds={bookmakerBackOdds || []}
                lay_odds={bookmakerLayOdds || []}
                setSlipObj={setSlipObj}
                handelBookmakerPositions={handelBookmakerPositions}
                slipObj={slipObj}
                messaageBox={messaageBox}
                resetSelection={resetSelection}
                profileData={
                  details?.matchSetting?.length > 0
                    ? { ...details, arrayCheck: "details" }
                    : { ...profileData, arrayCheck: "profile" }
                }
                user={user}
                bottomSpaceClass={
                  details?.fancy == "off" && details?.premiumFancy == "off"
                    ? "default-bookmaker"
                    : ""
                }
              />
            )}
            {moment(new Date()).isBefore(
              moment(details.eventDateTime).subtract(60, "m").toDate()
            ) &&
              details?.isTossEnable &&
              details?.matchOdds == "on" &&
              selections?.length > 0 &&
              back_odds?.length > 0 &&
              back_odds.filter((el) => el?.rt !== 0).length > 0 &&
              lay_odds.filter((el) => el?.rt !== 0).length > 0 &&
              back_oddsToss?.length > 0 &&
              details?.gameType == "cricket" && (
                <Toss
                  betType="toss"
                  data={tossList}
                  // bookmakerObj={bookmakerData}
                  details={details}
                  back_odds={back_oddsToss || []}
                  tossObj={tossObj || []}
                  setSlipObj={setSlipObj}
                  handelBookmakerPositions={tossPosition}
                  slipObj={slipObj}
                  messaageBox={messaageBox}
                  resetSelection={resetSelection}
                  profileData={
                    details?.matchSetting?.length > 0
                      ? { ...details, arrayCheck: "details" }
                      : { ...profileData, arrayCheck: "profile" }
                  }
                  user={user}
                  bottomSpaceClass={
                    details?.fancy == "off" && details?.premiumFancy == "off"
                      ? "default-bookmaker"
                      : ""
                  }
                  // handelBetFairPositions={handelBetFairPositions}
                />
              )}

            {/* Fancy Listing  */}
            {details?.fancy == "off" && details?.premiumFancy == "off" ? (
              ""
            ) : (
              // ( fancyList?.length > 0 || preminumFancy?.length > 0) ? (
              <div
                id={
                  key == "Fancy Bet"
                    ? "fancyBetTable_32011225"
                    : "sportsBookWrap"
                }
                style={{
                  display: "block",
                  marginBottom:
                    fancyCategoryTabArray?.filter(
                      (res) => res?.odds?.rt?.length > 0
                    )?.length > 0 || preminumFancy?.length > 0
                      ? "210px"
                      : "210px",
                  background: "#e0e6e",
                }}
              >
                {/* { console.log("preminumFancy",preminumFancy)} */}
                <div
                  id={key == "Fancy Bet" ? "fancyBetHead" : "sportsBookHead"}
                  className={
                    key == "Fancy Bet"
                      ? "fancy_bet-head"
                      : "sportsbook_bet-head"
                  }
                >
                  <h4
                    className="in-play"
                    onClick={() => {
                      setKey(
                        key == "Fancy Bet" ? "Fancy Bet" : "Preminum Fancy"
                      );
                      setPopupMessage(false);
                    }}
                  >
                    {/* {console.log("--------------------->",key)} */}
                    {key == "Fancy Bet" &&
                    details?.fancy == "on" &&
                    details?.gameType == "cricket" ? (
                      <>
                        <span>
                          <pre>in-play</pre>
                          Fancy Bet
                        </span>
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            key == "Fancy Bet"
                              ? setFancyRules(true)
                              : setPremiumFancyRules(true);
                            setPopupMessage(false);
                          }}
                          className="btn-head_rules"
                        >
                          Rules
                        </a>
                      </>
                    ) : (
                      details?.premiumFancy == "on" &&
                      !details?.eventName.includes(" T10 ") && (
                        <>
                          <span>
                            <pre>in-play</pre>
                            Preminum Fancy
                          </span>
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              key == "Fancy Bet"
                                ? setFancyRules(true)
                                : setPremiumFancyRules(true);
                              setPopupMessage(false);
                            }}
                            className="btn-head_rules"
                          >
                            Rules
                          </a>
                        </>
                      )
                    )}
                  </h4>
                  {key == "Fancy Bet" ? (
                    details?.premiumFancy == "on" &&
                    !details?.eventName.includes(" T10 ") ? (
                      <a
                        href="javascript:void(0)"
                        id="showSportsBookBtn"
                        onClick={() => {
                          setKey("Preminum Fancy");
                          setPopupMessage(false);
                        }}
                        className="other-tab"
                      >
                        Preminum Fancy
                      </a>
                    ) : (
                      ""
                    )
                  ) : details?.gameType == "cricket" &&
                    details?.fancy == "on" &&
                    !details?.eventName.includes(" SRL ") ? (
                    <a
                      href="javascript:void(0)"
                      id="showSportsBookBtn"
                      onClick={() => {
                        setKey("Fancy Bet");
                        setPopupMessage(false);
                      }}
                      className="other-tab"
                    >
                      Fancy Bet
                    </a>
                  ) : (
                    ""
                  )}
                  {key == "Preminum Fancy" && !isEmpty(user) && (
                    <>
                     <a
                          id="minMaxBtn_2"
                          // onClick={() => setPopupMessage(true)}
                          href="javascript:void(0)"
                          class="btn-fancy_info"
                        >
                          Min {" "+minSportBook}
                        </a>
                      {/* {popupMessage && (
                        <div
                          id="minMaxWrap_2"
                          class="fancy_info-popup"
                          style={{ display: "flex" }}
                        >
                          <dl>
                            <dt>Min</dt>
                            <dd id="minMaxInfo_2">{minSportBook}</dd>
                          </dl>

                          <a
                            href="javascript:void(0)"
                            onClick={() => setPopupMessage(false)}
                            class="close"
                          >
                            Close
                          </a>
                        </div>
                      )} */}
                    </>
                  )}
                </div>
                {/* {console.log("fancyCategoryTabArray",fancyCategoryTabArray)} */}
                {key == "Fancy Bet" &&
                details.fancy == "on" &&
                details?.gameType == "cricket" ? (
                  <Fancy
                    betType="fancy"
                    data={fancyCategoryTabArray || []}
                    details={details}
                    fancyList={fancyList || []}
                    back_odds={[]}
                    lay_odds={[]}
                    setSlipObj={setSlipObj}
                    isTv={isTv}
                    resetSelection={resetSelection}
                    slipObj={slipObj}
                    key={key}
                    loader={loader}
                    messaageBox={messaageBox}
                    setIsTv={setIsTv}
                    fancyCategory={fancyCategory}
                    setSelectedTabFunc={setSelectedTabFunc}
                    selectedTab={selectedTab}
                    profileData={
                      details?.matchSetting?.length > 0
                        ? { ...details, arrayCheck: "details" }
                        : { ...profileData, arrayCheck: "profile" }
                    }
                    user={user}
                  />
                ) : key == "Preminum Fancy" && details?.premiumFancy == "on" ? (
                  <PremiumFancyNew
                    preminumFancyTab={preminumFancyTab}
                    setSelectedTabFuncPreminum={[]}
                    data={preminumFancy || []}
                    betType={"premiumFancy"}
                    setSlipObj={setSlipObj}
                    setPremiumFancy={setPremiumFancy}
                    slipObj={slipObj}
                    resetSelection={resetSelection}
                    key={key}
                    messaageBox={messaageBox}
                    details={details}
                    activeSportBook={activeSportBook}
                    setActiveSportBook={setActiveSportBook}
                    profileData={
                      details?.matchSetting?.length > 0
                        ? { ...details, arrayCheck: "details" }
                        : { ...profileData, arrayCheck: "profile" }
                    }
                    user={user}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {details?.premiumFancy == "on" && (
              <PremiumFancyNew
                preminumFancyTab={preminumFancyTab}
                setSelectedTabFuncPreminum={[]}
                data={preminumFancy || []}
                betType={"premiumFancy"}
                setSlipObj={setSlipObj}
                setPremiumFancy={setPremiumFancy}
                slipObj={slipObj}
                resetSelection={resetSelection}
                key={key}
                messaageBox={messaageBox}
                details={details}
                activeSportBook={activeSportBook}
                setActiveSportBook={setActiveSportBook}
                profileData={
                  details?.matchSetting?.length > 0
                    ? { ...details, arrayCheck: "details" }
                    : { ...profileData, arrayCheck: "profile" }
                }
                user={user}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
export default BidDetail;

import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import BetSlipContext from "../../context/BetSlipContext";
import Keyboard from "./Keyboard";

const Fancy = ({
  betType,
  data,
  details,
  slipObj,
  setSlipObj,
  fancyCategory,
  setSelectedTabFunc,
  selectedTab,
  profileData,
  user,
  resetSelection,
  isTv,
  setIsTv,
  key,
  messaageBox,
  loader,
  fancyList,
}) => {
  const myRef = useRef(null);
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const handleScroll = () => {
    setId("");
    setId2("");
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(id)) {
      myRef.current.scrollIntoView(true);
    }
  }, [id]);

  const [amountRange, setAmountRange] = useState({
    min: process.env.REACT_APP_AMOUNT_RANGE_MIN
      ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
      : 1,
    max: process.env.REACT_APP_AMOUNT_RANGE_MAX
      ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
      : 100,
    oddsLimit: 1000,
  });

  useEffect(() => {
    if (!isEmpty(user)) {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == betType &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(obj)) {
        setAmountRange({
          ...obj,
          min: process.env.REACT_APP_AMOUNT_RANGE_MIN
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
            : Number(obj?.minAmount),
          max: process.env.REACT_APP_AMOUNT_RANGE_MAX
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
            : Number(obj?.maxAmount),
          oddsLimit: obj.oddsLimit ? obj.oddsLimit : 0,
        });
      }
    }
  }, [data]);
  const [popupMessage, setPopupMessage] = useState({
    check: false,
    id: "",
  });

  let {
    betSelectionObj,
    setBetSelectionObj,
    setBetFairObj,
    defaultStake,
    defaultOdds,
    setBookPosition,
    setBookPositionData,
  } = useContext(BetSlipContext);
  useEffect(() => {
    if (
      betSelectionObj?.betType == "fancy" &&
      id &&
      isEmpty(data?.find((res) => res?.selectionId == id)?.odds)
    ) {
      setBetSelectionObj({});
      setBetFairObj({});
      setPopupMessage({ check: false, id: "" });
    }
  }, [betSelectionObj, data]);

  // const fancyFilter=[
  // "match 1st over run",
  // "4 over run",
  // "6 over run",
  // "10 over run",
  // "15 over run",
  // "20 over run",
  // "25 over run",
  // "30 over run",
  // "35 over run",
  // "40 over run",
  // "45 over run",
  // "50 over run",
  // "fall of 1st wkt",
  // "fall of 2nd wkt",
  // "fall of 3rd wkt",
  // "fall of 4th wkt",
  // "fall of 5th wkt",
  // "fall of 6th wkt",
  // "fall of 7th wkt",
  // "fall of 8th wkt",
  // "fall of 9th wkt",
  // "fall of 10th wkt"]

  // const [fancyData, setFancyData] = useState({});
  //    useEffect(() => {
  //     if (details?.gameType == "cricket" && data.length>0) {

  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 10th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 9th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 8th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 7th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 6th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 5th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 4th wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 3rd wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 2nd wkt")) { return -1; } return 0; });
  //         // data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("Fall of 1st wkt")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("wkt")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("50 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("45 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("40 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("35 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("30 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("25 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("20 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("15 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("10 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("6 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("4 over run")) { return -1; } return 0; });
  //         data.sort(function (a, b) { if (a.fancyName && a.fancyName.includes("1st over run")) { return -1; } return 0; });
  //     }
  //       setFancyData(data);
  //   }, [details?.gameType ,data]);

  return fancyList?.length > 0 ? (
    <>
      <div
        id="fancyBetTabWrap"
        className="fancy_bet_tab-wrap ps ps--theme_default ps--active-x"
      >
        <ul className="special_bets-tab" style={{ overflowX: "scroll" }}>
          {fancyCategory?.length > 0 &&
            fancyCategory?.map((res) => {
              return (
                <li
                  onClick={() => setSelectedTabFunc(res.type)}
                  className={selectedTab == res.type ? "select" : ""}
                >
                  <a href="javascript:void(0)">{res.name}</a>
                </li>
              );
            })}
        </ul>
      </div>
      <div id="fancyBetTag">
        {data?.length > 0 && (
          <dl class="bets-selections-head" style={{ background: "#fff" }}>
            <dt></dt>
            <dd class="mode-land"></dd>
            <dd class="mode-land"></dd>
            <dd style={{ color: "black" }}>No</dd>
            <dd style={{ color: "black" }}>Yes</dd>
            <dd class="mode-land"></dd>
            <dd class="mode-land"></dd>
          </dl>
        )}
        {data?.length > 0 &&
          data?.map((item, index) => {
            if (
              item?.odds?.ms == 4 ||
              item?.odds?.rt?.length == 0 ||
              item?.odds?.ms == 2 ||
              isEmpty(item?.odds?.rt)
            ) {
              return null;
            } else {
              let newPosition = "";
              if (slipObj?.name === item?.name) {
                if (slipObj?.ib) {
                  if (Math.abs(item?.position) > 0) {
                    let backLoss = Number(betSelectionObj?.bidPrice) + Number(item.position);
                    newPosition = Number(backLoss);
                  } else {
                    let backLoss = Number(betSelectionObj?.bidPrice);
                    newPosition = Number(backLoss);
                  }
                } else {
                  if (Math.abs(item?.position) > 0) {
                    let backProfit =
                      (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
                      newPosition = Number(backProfit) + Number(item.position)
                  } else {
                    let backProfit =
                      (Number(betSelectionObj?.bidPrice) * Number(slipObj?.pt)) / 100;
                    newPosition = Number(backProfit)
                  }
                }
              }
              let condition =
                slipObj?.name === item?.name &&
                betSelectionObj?.priceToogle &&
                betSelectionObj?.betType == "fancy" &&
                Math.abs(newPosition) > 0;
              return (
                <div
                  ref={item.selectionId == id && index == id2 ? myRef : null}
                  className={`bets-wrap fancy-bet ${
                    details.fancy == "off" ? "disabled" : ""
                  }`}
                >
                  <h5>
                    <span id="marketName">{item.fancyName}</span>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        setPopupMessage({
                          check: true,
                          id: item.selectionId,
                        })
                      }
                      id="open-fancy_info"
                      className="btn-fancy_info"
                    >
                      fancybet info
                    </a>
                    {popupMessage.check &&
                      item.selectionId == popupMessage.id && (
                        <div
                          id="fancy_popup2"
                          style={{
                            display: "flex",
                            zIndex: 10,
                          }}
                          className="fancy_info-popup"
                        >
                          <dl>
                            <dt>Min / Max</dt>
                            <dd id="minMax">
                              {" "}
                              {amountRange?.min?.toFixed(2)} /{" "}
                              {amountRange?.max?.toFixed(2)}
                            </dd>
                          </dl>
                          <dl>
                            <dt id="rebateName" style={{ display: "none" }}>
                              Rebate
                            </dt>
                            <dd id="rebate" style={{ display: "none" }}></dd>
                          </dl>
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              setPopupMessage({
                                check: false,
                                id: "",
                              })
                            }
                            id="close-fancy_info"
                            className="close"
                          >
                            Close
                          </a>
                        </div>
                      )}
                  </h5>

                  <dl className="bets-selections">
                    <dt class="line_market-selection">
                      <dl class="tips">
                      <dt>
                          {(Math.abs(item?.position) > 0 ||
                            Math.abs(newPosition) > 0) &&
                            Math.abs(item?.position) > 0 && (
                              <span
                                id="before"
                                style={{ marginRight: "5px" }}
                                class="lose"
                              >
                                (
                                {parseFloat(Math.abs(item.position)).toFixed(2)}
                                )
                              </span>
                            )}
                          {Math?.abs(newPosition) > 0 && condition && (
                            <span
                              id="after"
                              class="to-lose"
                              style={{ display: "block" }}
                            >
                              ({parseFloat(Math.abs(newPosition)).toFixed(2)})
                            </span>
                          )}
                        </dt>
                      </dl>
                    
                      {(item?.position != "" || item?.position === 0) &&
                        !isNaN(Math.abs(item?.position)) && (
                          <a
                            id="fancyBetBookBtn"
                            href="javascript:void(0)"
                            className="btn-book"
                            onClick={() => {
                              setBookPosition(true);
                              // setIsTv({ ...isTv, status: false });
                              setBookPositionData(item);
                            }}
                          >
                            Book
                          </a>
                        )}
                    </dt>

                    {item?.odds?.ms == 1 ? (
                      ""
                    ) : (
                      <dd
                        id="suspend"
                        className="suspend-fancy"
                        style={{ display: "flex" }}
                      >
                        <p id="info">
                          {item?.odds?.ms == 9
                            ? "Ball Running"
                            : item?.odds?.ms == 2
                            ? "In Active"
                            : item?.odds?.ms == 3
                            ? "Suspended"
                            : item?.odds?.ms == 4
                            ? "Closed"
                            : ""}
                        </p>
                      </dd>
                    )}
                    {loader &&
                    betSelectionObj?.betType == "fancy" &&
                    slipObj.ri == item.selectionId ? (
                      <dd id="delayBetting" class="suspend-fancy">
                        <p id="info"></p>
                      </dd>
                    ) : (
                      ""
                    )}
                    <dd
                      id="lay_1"
                      onClick={() => {
                        if (
                          !item?.odds?.rt[0]?.rt ||
                          details.fancy == "off" ||
                          details?.sport_setting?.status == "inActive"
                        ) {
                        } else {
                          if (!messaageBox) {
                            if (betSelectionObj.betType !== betType) {
                              resetSelection(betSelectionObj.betType);
                            }
                            setId(item.selectionId);
                            setId2(index);
                            setBetSelectionObj({
                              ...betSelectionObj,
                              betType: betType,
                              oddsSelect: item?.odds?.rt[0]?.rt,
                              fancyOddSelect: item?.odds?.rt[0]?.pt,
                              layorback: "lay",
                              inputType: "price",
                              expectany: defaultOdds,
                              bidPrice: defaultStake == 0 ? "" : defaultStake,
                              priceToogle: true,
                              priceToogleType: "odds",
                              minAmount: amountRange.min,
                              maxAmount: amountRange.max,
                            });

                            setSlipObj({
                              ...item,
                              ...item?.odds?.rt[0],
                              rt: item?.odds?.rt[0]?.rt,
                              pt: item?.odds?.rt[0]?.pt,
                              runnerName: item?.jsonData[0]?.runnerName,
                            });
                          }
                        }
                      }}
                      style={
                        !item?.odds?.rt[0]?.rt || details?.fancy == "off"
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                      className={
                        !item?.odds?.rt[0]?.rt ||
                        details?.fancy == "off" ||
                        details?.sport_setting?.status == "inActive"
                          ? "single-disabled"
                          : ""
                      }
                    >
                      <a
                        className={
                          item?.odds?.rt[0]?.rt == slipObj?.rt &&
                          item?.odds?.rt[0]?.pt == slipObj?.pt &&
                          slipObj.ri == item.selectionId &&
                          betSelectionObj?.priceToogle
                            ? `lay-1 select ${
                                Number(
                                  document.getElementById("odds1")?.name
                                ) !== item?.odds?.rt[0]?.rt && "spark"
                              }`
                            : `lay-1 ${
                                Number(
                                  document.getElementById("odds1")?.name
                                ) !== item?.odds?.rt[0]?.rt && "spark"
                              }`
                        }
                        id={"odds1"}
                        name={item?.odds?.rt[0]?.rt}
                        href="javascript:void(0)"
                      >
                        {item?.odds?.rt?.length > 0
                          ? item?.odds?.rt[0]?.rt
                          : !isEmpty(item?.odds?.temp) &&
                            item?.odds?.temp[0]?.rt}
                        {/* {vart1} */}
                        <span>
                          {item?.odds?.rt?.length > 0
                            ? item?.odds?.rt[0]?.pt
                            : !isEmpty(item?.odds?.temp) &&
                              item?.odds?.temp[0]?.pt}
                        </span>
                      </a>
                    </dd>
                    <dd
                      id="back"
                      onClick={() => {
                        if (
                          !item?.odds?.rt[1]?.rt ||
                          details?.fancy == "off" ||
                          details?.sport_setting?.status == "inActive"
                        ) {
                        } else {
                          if (!messaageBox) {
                            if (betSelectionObj.betType !== betType) {
                              resetSelection(betSelectionObj.betType);
                            }
                            setId(item.selectionId);
                            setId2(index);
                            setBetSelectionObj({
                              ...betSelectionObj,
                              betType: betType,
                              oddsSelect: item?.odds?.rt[1]?.rt,
                              layorback: "back",
                              inputType: "price",
                              bidPrice: defaultStake == 0 ? "" : defaultStake,
                              fancyOddSelect: item?.odds?.rt[1]?.pt,
                              priceToogle: true,
                              priceToogleType: "odds",
                              minAmount: amountRange.min,
                              expectany: defaultOdds,
                              maxAmount: amountRange.max,
                            });
                            setSlipObj({
                              ...item,
                              ...item?.odds?.rt[1],
                              rt: item?.odds?.rt[1]?.rt,
                              pt: item?.odds?.rt[1]?.pt,
                              runnerName: item?.jsonData[0]?.runnerName,
                            });
                          }
                        }
                      }}
                      style={
                        !item?.odds?.rt[1]?.rt ||
                        details?.fancy == "off" ||
                        details?.sport_setting?.status == "inActive"
                          ? { cursor: "not-allowed" }
                          : { cursor: "pointer" }
                      }
                      className={
                        !item?.odds?.rt[1]?.rt ||
                        details?.fancy == "off" ||
                        details?.sport_setting?.status == "inActive"
                          ? "single-disabled"
                          : ""
                      }
                    >
                      <a
                        className={
                          item?.odds?.rt[1]?.rt == slipObj?.rt &&
                          item?.odds?.rt[1]?.pt == slipObj?.pt &&
                          slipObj.ri == item.selectionId &&
                          betSelectionObj?.layorback == "back" &&
                          betSelectionObj?.priceToogle
                            ? `back-1 select ${
                                Number(
                                  document.getElementById("odds2")?.name
                                ) !== item?.odds?.rt[1]?.rt && "spark"
                              }`
                            : `back-1 ${
                                Number(
                                  document.getElementById("odds2")?.name
                                ) !== item?.odds?.rt[1]?.rt && "spark"
                              }`
                        }
                        id={"odds2"}
                        name={item?.odds?.rt[1]?.rt}
                        href="javascript:void(0)"
                      >
                        {item?.odds?.rt?.length > 0
                          ? item?.odds?.rt[1]?.rt
                          : !isEmpty(item?.odds?.temp) &&
                            item?.odds?.temp[1]?.rt}
                        <span>
                          {item?.odds?.rt?.length > 0
                            ? item?.odds?.rt[1]?.pt
                            : !isEmpty(item?.odds?.temp) &&
                              item?.odds?.temp[1]?.pt}
                        </span>
                      </a>
                    </dd>
                  </dl>
                  {betSelectionObj?.priceToogle &&
                    betSelectionObj?.betType == "fancy" &&
                    slipObj.ri == item.selectionId &&
                    item?.odds?.ms == "1" && (
                      <Keyboard
                        setSlipObj={setSlipObj}
                        details={details}
                        slipObj={slipObj}
                        amountRange={amountRange}
                        betType={betType}
                        resetSelection={resetSelection}
                      />
                    )}
                </div>
              );
            }
          })}
        {isEmpty(data) && (
          <div id="noGameWrap" class="bets-wrap">
            <p class="no-game">
              Fancybet market will be available after event In-Play
            </p>
          </div>
        )}
      </div>
    </>
  ) : (
    <div id="noGameWrap" class="bets-wrap">
      <p class="no-game">
        Fancybet market will be available after event In-Play
      </p>
    </div>
  );
};

export default Fancy;
